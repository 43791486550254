import { useEffect, useState } from "react";

interface IVideoCuntProps {
  handleStopVideoRecording: () => void;
  isPaused: boolean;
  isStarted: boolean;
}

const VideoCount = ({ handleStopVideoRecording, isPaused, isStarted }: IVideoCuntProps) => {
  const [videoCount, setVideoCount] = useState(0);
  const [isVideoCounting, setIsVideoCounting] = useState(isStarted);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (isVideoCounting && videoCount < 120) {
      interval = setInterval(() => {
        setVideoCount((prev) => prev + 1);
      }, 1000);
    } else if (videoCount >= 120) {
      setIsVideoCounting(false)
      setVideoCount(0);
      handleStopVideoRecording();
    }
    return () => clearInterval(interval);
  }, [isVideoCounting, videoCount]);

  useEffect(() => {
    if (isPaused) {
      setIsVideoCounting(false);
    } else {
      setIsVideoCounting(true);
    }
  }, [isPaused])

  return <span className="absolute px-3 py-1 text-sm -translate-x-1/2 bg-white rounded-full top-8 left-1/2">
    <p className="font-semibold text-black">{videoCount}s / 120s</p>
  </span>
};

export default VideoCount;