import { useEffect, useRef } from "react";
import { IconLoader2, IconPlayerPauseFilled, IconPlayerPlayFilled, IconPlayerStopFilled, IconSend, IconX } from "@tabler/icons-react";
import getTextColor from "../../../hooks/getTextColorContrast";
import { EVideoStatus } from "../../input/ChatInput";
import { cn } from "../../../lib/utils";
import VideoCount from "./VideoCount";

interface IVideoProps {
	stream: MediaStream | null;
	themeData: ICreateThemeData;
	statusVideo: string;
	handleSubmitVideo: () => void;
	handleStopVideoRecording: () => void;
	handlePauseVideoRecording: () => void;
	handleResumeVideoRecording: () => void;
	handleExitVideoRecording: () => void;
	isTestChat: boolean;
	mediaBlobUrl: string | undefined;
}

function VideoPreview({ stream, themeData, statusVideo, handleSubmitVideo,
	handleStopVideoRecording, handlePauseVideoRecording, handleResumeVideoRecording, handleExitVideoRecording,
	isTestChat, mediaBlobUrl }: IVideoProps) {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoRef.current && stream) {
			videoRef.current.srcObject = stream;
		}
	}, [stream]);

	if (!stream) {
		return null;
	}

	return (
		<div className={cn(
			isTestChat ? "absolute max-w-[350px] w-full h-[350px] mx-2 mb-2 -translate-x-1/2 bottom-28 left-1/2" :
				"absolute max-w-[500px] min500:max-w-[98%] min500:h-[calc(100dvh-120px)] min500:mx-0 w-full min500:mb-0 min500:bottom-20 h-[500px] mx-2 mb-2 -translate-x-1/2 bottom-28 left-1/2")}>
			<div
				style={{ backgroundColor: themeData?.["chat-primary"] || '#FF6F98' }}
				className="relative flex flex-col justify-between w-full h-full gap-4 p-4 rounded-xl">
				{
					statusVideo !== EVideoStatus.ACQUIRING_MEDIA && statusVideo !== EVideoStatus.STOPPED &&
					<video
						ref={videoRef}
						autoPlay
						playsInline
						className="object-cover w-full h-full max-h-[400px] rounded-xl min500:max-h-[calc(100dvh-220px)]"
					/>
				}

				{
					statusVideo == EVideoStatus.STOPPED &&
					<div className="relative w-full h-full max-h-[400px] min500:max-h-[calc(100dvh-220px)]">
						<p className="absolute px-2 py-1 text-sm font-semibold text-black bg-white rounded-full top-3 left-3">
							Replay
						</p>
						<video
							src={mediaBlobUrl}
							controls
							playsInline
							className="object-cover w-full h-full max-h-[400px] rounded-xl min500:max-h-[calc(100dvh-220px)]"
						/>
					</div>
				}

				<button
					onClick={handleExitVideoRecording}
					type="button" className="absolute p-1 bg-white border-2 border-black rounded-full top-2 right-2">
					<IconX size={20} className="text-black" />
				</button>

				{
					statusVideo === EVideoStatus.ACQUIRING_MEDIA &&
					<div className="flex items-center justify-center w-full h-full gap-3">
						<IconLoader2 size={30} className="text-white animate-spin" />
						<p className="text-lg font-bold text-white">Acquiring media...</p>
					</div>
				}

				{
					(statusVideo === EVideoStatus.RECORDING || statusVideo === EVideoStatus.PAUSED) &&
					<VideoCount
						handleStopVideoRecording={handleStopVideoRecording}
						isPaused={statusVideo === EVideoStatus.PAUSED}
						isStarted={statusVideo === EVideoStatus.RECORDING}
					/>
				}

				{
					statusVideo === EVideoStatus.PAUSED &&
					<span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ">
						<IconPlayerPauseFilled className="text-white/30 mb-14" size={100} />
					</span>
				}

				<div className="flex items-center w-full gap-3">
					{
						statusVideo !== 'stopped' && statusVideo !== EVideoStatus.ACQUIRING_MEDIA
						&& <div className="flex items-center w-full gap-2">
							{
								statusVideo === EVideoStatus.PAUSED &&
								<button
									onClick={handleResumeVideoRecording}
									style={{
										backgroundColor: themeData?.primary || '#096846',
										color: getTextColor(themeData?.primary || '#096846')
									}}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full">
									<IconPlayerPlayFilled size={22} className="mt-0.5" />
									Continue
								</button>
							}

							{
								statusVideo === EVideoStatus.RECORDING &&
								<button
									onClick={handlePauseVideoRecording}
									style={{
										backgroundColor: themeData?.primary || '#096846',
										color: getTextColor(themeData?.primary || '#096846')
									}}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full">
									<IconPlayerPauseFilled size={22} className="mt-0.5" />
									Pause
								</button>
							}

							{
								statusVideo === EVideoStatus.RECORDING &&
								<button
									onClick={handleStopVideoRecording}
									style={{
										backgroundColor: themeData?.primary || '#096846',
										color: getTextColor(themeData?.primary || '#096846')
									}}
									type="button"
									className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full">
									<IconPlayerStopFilled size={22} className="mt-0.5" />
									Stop
								</button>
							}
						</div>
					}

					{
						statusVideo === EVideoStatus.STOPPED &&
						<button
							onClick={handleSubmitVideo}
							style={{
								backgroundColor: themeData?.primary || '#096846',
								color: getTextColor(themeData?.primary || '#096846')
							}}
							type="button"
							className="flex items-center justify-center w-full gap-2 px-5 py-2 text-white rounded-full">
							<IconSend size={20} />
							Send
						</button>
					}
				</div>
			</div>
		</div>
	);
}

export default VideoPreview;